/* UnderConstruction.css */
.under-construction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f4f8;
    color: #333;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .logo-placeholder {
    width: 250px;
    height: 250px;
    border-radius: 50%; /* Makes the div round */
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .under-construction  .hero{
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 600px) {
    .logo-placeholder {
      width: 90px;
      height: 90px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }
  }
  